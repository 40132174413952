const isComplete = profile => {
  if (
    profile?.genderId &&
    profile?.countryCode !== null &&
    profile?.countryCode?.trim() !== '' &&
    profile?.phone !== null &&
    profile?.phone?.trim() !== '' &&
    profile?.address !== null &&
    profile?.address?.countryId &&
    profile?.address?.cityId &&
    profile?.address?.location &&
    ((profile?.linkedinUrl !== null && profile?.linkedinUrl?.trim() !== '') ||
      (profile?.cvUrl !== null && profile?.cvUrl?.trim() !== ''))
  ) {
    return 2;
  }
  if (profile?.birthdate !== null && profile?.address !== null && profile?.address?.countryId) {
    return 1;
  }

  return 0;
};

export default isComplete;
