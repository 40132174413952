import { useEffect, useRef, useState } from 'react';
import arrowIcon from '../../assets/newSteps/arrow-small.svg';
import useClickOutside from '../../hooks/useClickOutside';
import useTranslations from '../../hooks/useTranslations';
import './DropdownSelect.scss';

const DropdownSelect = ({
  placeholder,
  options = [],
  className,
  setState,
  selected,
  allOption,
  disabled,
  selectedPlaceholder,
  position = 'right',
  responsive,
  onClickAction,
  dataTestId,
}) => {
  const { t } = useTranslations();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [selectedPlaceholders, setSelectedPlaceholders] = useState(placeholder ?? '');
  useClickOutside(ref, () => setOpen(false));

  const handleClick = value => {
    setState(value);
    setOpen(false);
  };

  useEffect(() => {
    if (options.length && selected) {
      const selectedOption = options.find(
        option => (option.id && option.id === selected) || (option.value && option.value === selected),
      );
      if (selectedOption) {
        setState({
          id: selectedOption.value ?? selectedOption.id,
          name: selectedOption.label ?? '',
        });
      } else {
        setState(null);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedPlaceholder && selected) {
      setSelectedPlaceholders(
        options.find(op => op?.id === selected || op?.value === selected)?.tag ??
          options.find(op => op?.id === selected || op?.value === selected)?.name ??
          placeholder,
      );
    }
  }, [selected]);

  return (
    <div
      onClick={onClickAction}
      className={`flex py-2 self-end align-self-sm-center dropdown-max-height ${open && 'dropdown-container-index'}`}>
      <div
        ref={ref}
        className={`border rounded font-roboto font-bold  bg-white dark:bg-dark-normal dark:text-white non-selectable dropdown-select-container relative w-max-content ${className}`}>
        <div
          onClick={disabled ? null : () => setOpen(state => !state)}
          aria-hidden
          className='dropdown-select-button cursor-pointer p-2 w-full flex justify-between'>
          <span
            className={`dropdown-select-placeholder font-weight-normal overflow-hidden ${disabled && 'text-muted'}`}>
            {selectedPlaceholders}
          </span>
          {!disabled && (
            <img
              src={arrowIcon}
              alt='arrow'
              className={`dropdown-select-arrow mx-2 ${open && 'dropdown-select-arrow-open'}`}
            />
          )}
        </div>
        <ul
          className={`dropdown-select-options-container bg-white dark:bg-dark-normal overflow-auto custom-scrollbar m-0 absolute ${
            open && 'dropdown-select-options-container-open py-2 border'
          } ${position === 'right' ? 'left-0' : 'right-0'}`}
          data-testid={dataTestId}>
          {allOption && (
            <li
              className='p-2 cursor-pointer dropdown-selec-option dark:hover:bg-alkemy-black'
              onClick={() => handleClick({ name: t('ALL'), id: null })}
              onKeyPress={() => handleClick({ name: t('ALL'), id: null })}
              role='option'
              aria-selected>
              {t('ALL')}
            </li>
          )}
          {options.map((option, i) => (
            <li
              data-testid={`dropdown-option-${option.name}`}
              className={`p-2 cursor-pointer dropdown-selec-option dark:hover:bg-alkemy-black ${
                selected &&
                (selected === option.value || selected === option.id) &&
                'dropdown-selected-option dark:bg-alkemy-black'
              } ${responsive && 'text-dark'}`}
              key={i}
              onClick={() =>
                handleClick({
                  id: option.value ?? option.id,
                  ...(option.label ? { name: option.label } : {}),
                })
              }
              onKeyPress={() =>
                handleClick({
                  id: option.value ?? option.id,
                  ...(option.label ? { name: option.label } : {}),
                })
              }
              role='option'
              aria-selected>
              {option.label ?? option.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DropdownSelect;
