import { notification } from 'antd';
import useSound from 'use-sound';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useContextState, ActionTypes } from '../redux/contextState';
import useTranslations from './useTranslations';
import notificationSound from '../assets/notification.mp3';
import { io } from 'socket.io-client';
import * as Sentry from '@sentry/react';
import {
  getMaintenance,
  getProfile,
  getScreeningInvitation,
  getWhiteLabel,
  getProfileNotifications,
  changeDisabledAccount,
} from '../api/userService';
import isComplete from '../utils/steps/isComplete';
import { requiredRoles } from '../constants/roles';
import { Identify } from '../utils/segmentUtils';
import routes from '../config/routes';
import { appendUrl, createUrl, windowHashIncludes } from '../utils/windowUtils';
import NotificationsEmpty from '../assets/header/notifications-empty';
import { auth0Client } from '../auth0/auth0';
import { roles } from '../constants/enums';
import { Window } from '../helpers/window';

const useRedirect = () => {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const history = useHistory();
  let welcomePage;
  const [play] = useSound(notificationSound, {
    volume: 0.5,
  });

  useEffect(() => {
    if (contextState.isAuthenticated && contextState?.profile?.id) {
      const socket = io(`${process.env.RAZZLE_RUNTIME_ALKYMETRICS_V2_URL}/?id=${contextState?.profile?.id}`, {
        reconnectionAttempts: 5,
        transports: ['websocket'],
      });

      socket.on('notifications', message => {
        setContextState({
          type: ActionTypes.SetNotif,
          value: message,
        });
        notification.open({
          message: t('NOTIFICATIONS_NEW_NOTIFICATION'),
          icon: <NotificationsEmpty color={contextState.theme === 'dark' ? '#ffffff' : 'grey'} />,
          onClick: () => {
            setContextState({ type: ActionTypes.SetNotificationDropdown, value: true });
            notification.destroy();
          },
          placement: 'bottomRight',
        });
        play();
      });

      Identify(contextState?.profile?.id, contextState?.profile);
    }
  }, [contextState.isAuthenticated, contextState?.profile?.id]);

  useEffect(async () => {
    if (contextState.isAuthenticated) {
      if (history.location.pathname === '/logout') {
        auth0Client().logout({ returnTo: `${window.location.origin}/register` });
      } else {
        if (localStorage.getItem('token')) {
          console.log('AHORA');
          await getWhiteLabel(Window()?.localStorage?.getItem('language') ?? 'es').then(res => {
            setContextState({
              type: ActionTypes.SetWhitelabel,
              value: res,
            });
            if (res.routes.includes('WELCOME')) {
              welcomePage = true;
            }
          });
        }
        const redirectURL = new URL(process.env.RAZZLE_RUNTIME_SCREENINGS);
        createUrl(redirectURL, contextState.profile.id);
        if (contextState.screeningRedirection.screeningId) {
          appendUrl('screeningId', contextState.screeningRedirection.screeningId);
          window.location.href = redirectURL.toString();
        }
        if (contextState.screeningRedirection.technologyId && contextState.screeningRedirection.levelId) {
          appendUrl(redirectURL, 'technologyId', contextState.screeningRedirection.technologyId);
          appendUrl(redirectURL, 'levelId', contextState.screeningRedirection.levelId);
          window.location.href = redirectURL.toString();
        }
        if (localStorage?.getItem('prevHash')) {
          window.location.hash = localStorage?.getItem('prevHash');
          localStorage.removeItem('prevHash');
        }
        if (contextState.askMultiplatformAccount) {
          history.push('/multi-account');
          setContextState({
            type: ActionTypes.SetLoading,
            value: false,
          });
          return;
        }
        if (
          !contextState.roles.map(rol => rol).some(role => requiredRoles.includes(role)) &&
          !contextState.roles.map(rol => rol).includes(roles.ADMIN)
        ) {
          history.push('/unauthorizated');
          setContextState({
            type: ActionTypes.SetLoading,
            value: false,
          });
          return;
        }
        let profile;
        let maintenance = false;
        let screenings;
        let error = false;
        getMaintenance().then(res => {
          maintenance = res?.isOnMaintenance;
        });
        await getProfile()
          .then(async response => {
            if (response?.accountDisabled) {
              await changeDisabledAccount(response?.id, false);
            }
            setContextState({
              type: ActionTypes.SetProfile,
              value: response,
            });
            profile = response;
          })
          .catch(() => {
            setContextState({
              type: ActionTypes.SetError,
            });
            error = true;
          });
        await getScreeningInvitation().then(async res => {
          setContextState({
            type: ActionTypes.SetScreenings,
            value: res,
          });
          screenings = res;
        });
        await getProfileNotifications()
          .then(response => {
            setContextState({
              type: ActionTypes.SetProfileNotifications,
              value: response,
            });
          })
          .catch(err => {
            Sentry.captureException(err);
            setContextState({
              type: ActionTypes.SetProfileNotifications,
              value: [],
            });
          });
        if (localStorage.getItem('prevUrl') && !!isComplete(profile)) {
          history.push(`${JSON.parse(localStorage.getItem('prevUrl'))}${windowHashIncludes('#access_token')}`);
          localStorage.removeItem('prevUrl');
        }
        if (maintenance) {
          history.push('/maintenance');
          setContextState({
            type: ActionTypes.SetLoading,
            value: false,
          });
          return;
        }
        if (error || contextState.error) {
          history.push({
            pathname: '/error',
            hash: windowHashIncludes('#access_token'),
          });
          return;
        }
        if (isComplete(profile) === 0) {
          history.push({
            search:
              '?' +
              decodeURIComponent(
                new URLSearchParams({
                  page: profile?.birthdate === null ? 1 : 2,
                }).toString(),
              ),
            pathname: '/steps',
            hash: windowHashIncludes('#access_token'),
          });
          setContextState({
            type: ActionTypes.SetLoading,
            value: false,
          });
          return;
        }
        if (
          (!routes.logged.map(route => route.path).includes(history.location.pathname) &&
            !routes.logged.map(route => route.path).includes('/' + history.location.pathname.split('/')[1])) ||
          ['/maintenance', '/error', '/login', '/unauthorizated', '/steps'].includes(history.location.pathname)
        ) {
          if (isComplete(profile) === 1 || isComplete(profile) === 2) {
            if (
              contextState?.roles?.includes(roles.APPLICANT) ||
              (contextState?.roles?.includes(roles.PREACCELERATION) && !contextState?.roles?.includes(roles.ALKYMER))
            ) {
              if (welcomePage) {
                history.push({
                  pathname: '/welcome',
                  hash: windowHashIncludes('#access_token'),
                });
                setContextState({
                  type: ActionTypes.SetLoading,
                  value: false,
                });
                return;
              }
              history.push({
                pathname: '/home',
                hash: windowHashIncludes('#access_token'),
              });

              setContextState({
                type: ActionTypes.SetLoading,
                value: false,
              });
              return;
            }
            history.push({
              pathname: '/home',
              hash: windowHashIncludes('#access_token'),
            });
            setContextState({
              type: ActionTypes.SetLoading,
              value: false,
            });
          }
        }
        setContextState({
          type: ActionTypes.SetLoading,
          value: false,
        });
      }
    }
  }, [contextState.isAuthenticated]);

  const handleError = error => {
    setContextState({
      type: ActionTypes.SetError,
    });
    history.push({
      pathname: '/error',
      hash: windowHashIncludes('#access_token'),
    });
  };

  useEffect(() => {
    if (contextState.error) {
      history.push('/error');
    }
  }, [contextState.error]);

  useEffect(() => {
    if (history.location.pathname !== '/profile') {
      setContextState({
        type: ActionTypes.SetEditable,
        value: false,
      });
      if (contextState.show) {
        setContextState({
          type: ActionTypes.SetShow,
          value: false,
        });
      }
    }
  }, [history.location.pathname, contextState.show]);
  return { handleError };
};

export default useRedirect;
