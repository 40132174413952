import moment from 'moment';

export const formatDate = (translateFn, d, type = 'd/m/y') => {
  const date =
    moment(d)
      .format(`dddd DD/MM [${translateFn('AT')}] HH:mm`)[0]
      .toUpperCase() +
    moment(d)
      .format(`dddd DD/MM [${translateFn('AT')}] HH:mm`)
      .slice(1);
  if (type === 'eventsDate') {
    return date;
  }
  return moment(d).format('DD/MM/YYYY');
};
