import { useHistory } from 'react-router-dom';
import { ActionTypes, useContextState } from '../redux/contextState';

const useVideoModal = () => {
  const { setContextState } = useContextState();
  const history = useHistory();

  const openModal = video => {
    const root = document.getElementById('root');
    setContextState({
      type: ActionTypes.SetVideoSelected,
      value: video,
    });
    history.push(`${history.location.pathname}#video`);
    setTimeout(() => {
      root.classList.add('overflow-hidden');
      root.style.height = '100vh';
    }, 300);
  };

  const closeModal = () => {
    const root = document.getElementById('root');
    setContextState({
      type: ActionTypes.SetVideoSelected,
      value: {},
    });
    root.classList.remove('overflow-hidden');
    root.style.height = 'auto';
    history.goBack();
  };

  return { openModal, closeModal };
};

export default useVideoModal;
