export const optionsAlkymerSignup = {
  CERTIFICATION: 'CERTIFICATION',
  FIND_JOB: 'FIND_JOB',
  IMPROVE_SKILLS: 'IMPROVE_SKILL',
  TEST_INVITATION: 'TEST_INVITATION',
};

export const roles = {
  ADMIN: 'Admin',
  PREACCELERATION: 'Preacceleration',
  APPLICANT: 'Applicant',
  MELI: 'Meli',
  MENTOR: 'Mentor',
  PARTNER: 'Partner',
  ALKYMER: 'Alkymer',
  BETATESTER: 'BetaTester',
  EXCLUSIVEUSER: 'ExclusiveUser',
};
