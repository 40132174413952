import useTranslations from '../../hooks/useTranslations';
import ScreeningInvitationCard from '../ScreeningInvitationCard/ScreeningInvitationCard';

const ScreeningInvitations = ({ invitations }) => {
  const { t } = useTranslations();

  const filteredInvitations = invitations
    .filter(invitation => {
      return (
        new Date(invitation.stage?.endDate).getTime() > new Date().getTime() &&
        invitation.status !== 'IN_PROGRESS' &&
        invitation.status !== 'FINISHED' &&
        invitation.status !== 'PROCESSING'
      );
    })
    .sort((a, b) => {
      return new Date(a.stage.endDate).getTime() - new Date(b.stage.endDate).getTime();
    });

  return (
    <>
      {!!filteredInvitations?.length && (
        <div
          className='description-container bg-white dark:bg-dark-bold rounded mx-auto p-4 mb-3'
          data-testid='invitations-container-div'>
          <span className='text-base font-bold dark:text-white' data-testid='invitations-title-span'>
            {t('SCREENINGS_INVITATIONS_TO')}
          </span>
          <div className='flex justify-around flex-wrap max-w-full p-5' data-testid='invitations-cards-div'>
            {filteredInvitations.map(invitation => {
              return <ScreeningInvitationCard key={invitation.id} {...invitation} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default ScreeningInvitations;
