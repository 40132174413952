import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Skeleton, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  getCertificatePublicUrl,
  getUserChallengeCertificates,
  qrCodeGenerator,
  updateChallengesCertificates,
} from '../../api/userService';
import diplomaAnimation from '../../assets/certificates/diploma.json';
import editIcon from '../../assets/certificates/edit.svg';
import diplomaIcon from '../../assets/certificates/diploma-flat.svg';
import shareIcon from '../../assets/certificates/share.svg';
import Banner from '../../components/Banner/Banner';
import LottieScreen from '../../components/LottieScreen/LottieScreen';
import useTranslations from '../../hooks/useTranslations';
import { useContextState } from '../../redux/contextState';

import { debounce } from 'lodash';
import { FileName } from '../../utils/constants';
import { downloadFile } from '../../utils/profile/downloadFile';
import Spinner from '../../components/Spinner/Spinner';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const Certificates = props => {
  const { t } = useTranslations();
  const { contextState, setContextState } = useContextState();
  const [editMode, setEditMode] = useState(false);

  const { data, isLoading, isError, refetch, error } = useQuery(
    ['certificates', contextState.profile.id],
    () => getUserChallengeCertificates(contextState.profile.id),
    {
      enabled: Boolean(contextState.profile.id),
      retry: 2,
    },
  );

  const {
    mutate,
    isLoading: isPostingData,
    error: errorPostingData,
    isSuccess: postedSuccessfully,
    reset,
  } = useMutation(async postData => {
    return await updateChallengesCertificates({ userId: contextState.profile.id, screeningInstances: postData });
  });

  const [certificatesVisibility, setCertificatesVisibility] = useState({});
  const [qrData, setQrData] = useState(null);
  const [qrImage, setQrImage] = useState(null);
  const [loadingQrDownload, setLoadingQrDownload] = useState(false);
  const onClickEdit = () => {
    reset();
    setEditMode(!editMode);
    setCertificatesVisibility({});
  };
  const onSuccess = () => {
    onClickEdit();
    refetch();
    notification.open({
      message: t('CERTIFICATES_EDIT_SUCCESS'),
      type: 'success',
    });
  };

  postedSuccessfully && onSuccess();

  errorPostingData &&
    notification.open({
      message: t('CERTIFICATES_EDIT_FAILED'),
      type: 'error',
    });

  const handlePostData = async () => {
    const arrayOfObjects = Object.entries(certificatesVisibility).map(([key, value]) => ({
      id: key,
      certificateVisible: value,
    }));
    mutate(arrayOfObjects);
  };

  const debouncedHandlePostData = debounce(() => {
    handlePostData();
  }, 300);

  const fetchQrData = async () => {
    const url = await getCertificatePublicUrl();
    const qr = await qrCodeGenerator({ text: url, fileName: FileName.CERTIFICATE });
    setQrData(qr);
    const blob = new Blob([qr.data], { type: qr.fileType });
    const urlImage = URL.createObjectURL(blob);
    setQrImage(urlImage);
  };
  useEffect(() => {
    fetchQrData();
    refetch();
  }, []);

  const downloadQrCode = async () => {
    try {
      downloadFile(qrData.data, qrData.fileType, qrData.fileName);
    } catch (error) {
      notification.open({
        message: t('ERROR_DOWNLOADING_QR'),
        type: 'error',
      });
    } finally {
    }
  };

  const copyTextToClipboard = async () => {
    const url = await getCertificatePublicUrl();
    await navigator.clipboard.writeText(url);
    notification.open({
      message: t('COPIED_TO_CLIPBOARD'),
      type: 'success',
    });
  };

  const debouncedCopyTextToClipboard = debounce(() => {
    copyTextToClipboard();
  }, 300);
  const debouncedDownloadQrCode = debounce(() => {
    downloadQrCode();
  }, 300);

  const handleDownloadPdf = async url => {
    try {
      setLoadingQrDownload(true);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      downloadFile(blob, 'application/png', `${FileName.CERTIFICATE}.png`);
    } catch (error) {
      notification.open({
        message: t('ERROR_DOWNLOADING_CERTIFICATE'),
        type: 'error',
      });
    } finally {
      setLoadingQrDownload(false);
    }
  };

  return (
    <div className='relative w-full pb-5'>
      <Banner text={t('MY_CERTIFICATES_LABEL')} icon={diplomaIcon} height='5rem' />
      {isLoading ? (
        <section data-testid='skeleton' className='flex w-full justify-center mt-8'>
          <div className='w-full lg:w-1/2 xl:w-1/2'>
            <div className='flex flex-wrap w-full justify-center gap-4'>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => (
                <div key={'skeleton-' + x} className='grow w-72'>
                  <Skeleton.Image active style={{ width: '100% !important' }} className='w-full' />
                </div>
              ))}
            </div>
          </div>
        </section>
      ) : (
        <section className='flex w-full justify-center mt-4'>
          <div className='flex w-full flex-col justify-center items-center'>
            {isError ? (
              <LottieScreen
                title={t('CERTIFICATES_ERROR_TITLE')}
                subtitle={t('CERTIFICATES_ERROR_SUBTITLE')}
                lottie={{
                  src: 'https://lottie.host/8584bdc0-6b5c-476f-8372-25a1664c5a56/gsuV5bzrcJ.json',
                  loop: 'true',
                }}
              />
            ) : (
              <>
                {data && data.length ? (
                  <div
                    data-testid='certificates-data-container'
                    className='w-3/4 p-4 rounded-xl bg-white shadow-xl dark:bg-dark-bold'>
                    {!editMode && (
                      <div className='flex w-full justify-end'>
                        <button
                          className='bg-transparent py-2 px-4 text-maya-blue font-bold border-1 border-maya-blue rounded-full flex items-center'
                          onClick={onClickEdit}>
                          <img src={editIcon} className='w-4 h-4 mx-2 hover:cursor-pointer' />
                          {t('CERTIFICATES_EDIT_BUTTON_LABEL')}
                        </button>
                      </div>
                    )}

                    <div className='flex flex-wrap justify-center my-6'>
                      {!editMode && data?.every(cert => cert.certificateVisible == false) ? (
                        <span className='font-medium text-lg'>{t('CERTIFICATES_NO_VISIBLE_AVAILABLE_LABEL')}</span>
                      ) : null}
                      {data?.map((cert, index) => {
                        if (!editMode && !cert.certificateVisible) return null;
                        return (
                          <div
                            className='flex flex-col items-center m-4 cursor-pointer overflow-hidden'
                            key={cert?._id}
                            onClick={() => {
                              if (editMode) {
                                setCertificatesVisibility({
                                  ...certificatesVisibility,
                                  [cert._id]:
                                    certificatesVisibility[cert._id] == undefined
                                      ? !cert.certificateVisible
                                      : !certificatesVisibility[cert._id],
                                });
                              } else {
                                handleDownloadPdf(cert.certificateUrl);
                              }
                            }}>
                            <div
                              className='bg-cover bg-center h-52 w-72 flex justify-center items-center'
                              style={{ backgroundImage: `url(${cert?.certificateUrl})` }}>
                              {editMode && (
                                <div className='flex w-full h-full justify-end items-top'>
                                  <div
                                    className='bg-light-blue rounded-l-full overflow-hidden self-start p-3 shadow-xl'
                                    style={{ backgroundColor: '#BAE2F6' }}>
                                    {certificatesVisibility[cert._id] ?? cert.certificateVisible ? (
                                      <FontAwesomeIcon icon={faEye} className='text-maya-blue ' fontSize={'1.5em'} />
                                    ) : (
                                      <FontAwesomeIcon icon={faEyeSlash} className='text-gray-500' fontSize={'1.5em'} />
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <span className='font-medium text-lg mt-2'>{cert?.screening?.name}</span>
                          </div>
                        );
                      })}
                    </div>

                    {editMode ? (
                      <>
                        <div className='flex w-full justify-center'>
                          <button
                            className='bg-transparent py-2 px-8 mx-2 text-maya-blue font-bold border-1 border-maya-blue rounded-full'
                            onClick={onClickEdit}
                            disabled={isPostingData}>
                            {t('CANCEL')}
                          </button>
                          <button
                            className='bg-maya-blue py-2 px-8 mx-2 text-white font-bold rounded-full'
                            disabled={isPostingData}
                            onClick={debouncedHandlePostData}>
                            {isPostingData ? (
                              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
                            ) : (
                              t('PROFILE_MENU_SAVE')
                            )}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='flex w-full justify-center'>
                          <button
                            className='bg-maya-blue py-2 px-4 text-white font-bold rounded-full flex items-center self-center'
                            onClick={debouncedCopyTextToClipboard}>
                            <img src={shareIcon} className='w-4 h-4 mx-2 hover:cursor-pointer' />
                            {t('CERTIFICATES_SHARE_WALL_BUTTON_LABEL')}
                          </button>
                        </div>
                        {qrImage && (
                          <button
                            data-testid='button-share-url-certificate'
                            onClick={debouncedDownloadQrCode}
                            className='px-6 py-2 mt-4  disabled:bg-opacity-60'>
                            <div className='flex flex-col items-center'>
                              <div className='bg-white p-3 shadow-lg rounded-xl my-2'>
                                <img src={qrImage} alt='Blob Image' height={50} width={100} />
                              </div>
                              <a className='btn-link text-md font-bold text-dark text-underline hover:underline dark:text-white '>
                                {t('CERTIFICATE_DOWNLOAD_QR_BUTTON')}
                              </a>
                            </div>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <LottieScreen
                    title={t('CERTIFICATES_EMPTY_TITLE')}
                    subtitle={t('CERTIFICATES_EMPTY_SEARCH_SUBTITLE')}
                    lottie={{
                      src: JSON.stringify(diplomaAnimation),
                      loop: 'false',
                    }}
                    urlRedirect='/inscription'
                  />
                )}
              </>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

Certificates.propTypes = {};

export default Certificates;
